//import 'alpinejs';

function init() {
  // INSERT STUFF IF NEEDED

  $('.main-video-container').hide();

  $(document).on('click', '.play-btn', function(e) {
    e.preventDefault();

    $('.b-video-with-teaser').addClass('active');

    $('.teaser-video').fadeOut(function() {
      $('.main-video-container').css('position', 'relative');
      var source = $('.main-video iframe').attr('src');
      $('.main-video iframe').attr('src', source + '&autoplay=1&rel=0');
      $('.main-video-container').fadeIn(function() {
      });
      $(this).remove();
    });
  });

}

export default {
  init,
};
