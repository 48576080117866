// https://github.com/bfred-it/object-fit-images
import objectFitImages from 'object-fit-images';

function init() {
  objectFitImages(); // this may require window.objectFitImages()
}

export default {
  init,
};
