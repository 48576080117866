/* eslint camelcase: "off" */
/* eslint no-unused-vars: "off" */
/* global tyomaa__cookie_consent */

import cookieconsent from 'cookieconsent';

function init() {
  if (typeof tyomaa__cookie_consent === 'undefined') {
    return;
  }

  window.cookieconsent.initialise({
    content: {
      message: tyomaa__cookie_consent.message,
      dismiss: tyomaa__cookie_consent.dismiss,
      link: tyomaa__cookie_consent.read_more,
      href: tyomaa__cookie_consent.href,
    },
    showLink: tyomaa__cookie_consent.href !== '',
  });
}

export default {
  init,
};
