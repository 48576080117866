import PerfectScrollbar from 'perfect-scrollbar';
import debounce from 'lodash-es/debounce';

let showEl;
let closeEl;
let toggleEl;
let navEl;
let backdropEl;

let scrollbar;

function show() {
  navEl.classList.add('is--open');
  toggleEl.classList.add('is--open');
  backdropEl.classList.add('is--open');
  document.body.classList.add('nav-panel--open');
}

function close() {
  navEl.classList.remove('is--open');
  toggleEl.classList.remove('is--open');
  backdropEl.classList.remove('is--open');
  document.body.classList.remove('nav-panel--open');
}

function toggle() {
  if (navEl.classList.contains('is--open')) {
    close();
  } else {
    show();
  }
}

// function handleBreakpoint(mql) {
//   if (mql.matches) {
//     close();
//   }
// }

function registerEvents() {
  if (showEl) showEl.addEventListener('click', show);
  if (closeEl) closeEl.addEventListener('click', close);
  if (backdropEl) backdropEl.addEventListener('click', close);
  if (toggleEl) toggleEl.addEventListener('click', toggle);

  // window.matchMedia('(min-width: 960px)').addListener(handleBreakpoint);

  document.addEventListener('keyup', (event) => {
    if (event.keyCode === 27) {
      close();
    }
  });

  scrollbar = new PerfectScrollbar('.nav-panel__scroller', {
    swipePropagation: false,
  });

  window.addEventListener('resize', debounce(() => {
    scrollbar.update();
  }, 100));
}

function init() {
  navEl = document.querySelector('.nav-panel');

  if (!navEl) {
    return;
  }

  showEl = document.querySelector('[data-action="nav-panel-show"]');
  closeEl = document.querySelector('[data-action="nav-panel-close"]');
  toggleEl = document.querySelector('[data-action="nav-panel-toggle"]');
  backdropEl = document.querySelector('.nav-panel__backdrop');

  registerEvents();
}

export default {
  init,
};
