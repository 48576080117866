import lozad from 'lozad';

class LazyLoadWrapper {
  constructor({
    // Default options.
    selector = '.lazy-load',
    firstLoadTargets = '',
  } = {}) {
    this.selector = document.querySelectorAll(selector);
    this.firstLoadTargets = (firstLoadTargets === '') ? [] : document.querySelectorAll(firstLoadTargets);
    this.observer = null;
  }

  mount() {
    this.observer = lozad(this.selector, {
      rootMargin: '500px 0px',
      loaded: (el) => {
        el.classList.add('is--lazy-loaded');
      },
    });

    this.observer.observe();

    if (this.firstLoadTargets.length > 0) {
      this.observer.triggerLoad(this.firstLoadTargets);
    }
  }
}

function init() {
  new LazyLoadWrapper({
    selector: '.lazy-load',
  }).mount();
}

export default {
  init,
};
