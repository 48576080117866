import Headroom from 'headroom.js';

const el = document.querySelector('.top-bar');

function init() {
  if (el) {
    const headroom = new Headroom(el, {
      offset: 20,
      tolerance: 5,
    });
    headroom.init();
  }
}

export default {
  init,
};
