const videoEl = document.querySelector('.video--play-inview');

function registerEvents() {
  if (!videoEl) {
    return;
  }

  let started = false;
  const inviewObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio >= 0.2 && !started) {
        videoEl.play();
        started = true;
      }
    });
  }, { threshold: [0.2] });

  inviewObserver.observe(videoEl);
}

function init() {
  if (videoEl) {
    registerEvents();
  }
}

export default {
  init,
};
