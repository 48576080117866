/* global $ */

function init() {
  // TODO: Smooth scroll
  $(document).on('gform_confirmation_loaded', (event, formId) => {
    const confirmationEl = document.querySelector(`#gform_confirmation_wrapper_${formId}`);

    if (confirmationEl) {
      confirmationEl.scrollIntoView();
    }
  });
}

export default {
  init,
};
