const backLinkEl = document.querySelector('.single-service__back a');

function init() {
  const isReferrerOK = (document.referrer && document.referrer.includes(window.location.origin));

  if (backLinkEl && isReferrerOK) {
    backLinkEl.href = document.referrer;
    backLinkEl.addEventListener('click', (event) => {
      event.preventDefault();
      window.history.back();
    });
  }
}

export default {
  init,
};
