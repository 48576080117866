import navPanel from '../../components/nav-panel/nav-panel';
import topBar from '../../components/top-bar/top-bar';
// import cardGroup from '../../components/card-group/card-group';
import cookieConsent from '../../components/cookie-consent/cookie-consent';
import gravityForms from '../../components/gravity-forms/gravity-forms';
import lazyLoad from '../../components/lazy-load/lazy-load';
import objectFit from '../../components/object-fit/object-fit';
import singleService from '../../blocks/single-service/single-service';
import videoObserver from '../../components/video-observer/video-observer';
import videoWithTeaser from '../../blocks/video-with-teaser/video-with-teaser';

function start() {
  navPanel.init();
  topBar.init();
  lazyLoad.init();
  videoObserver.init();
  objectFit.init();
  singleService.init();
  // cardGroup.init();
  cookieConsent.init();
  gravityForms.init();
  videoWithTeaser.init();
}

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', start);
} else {
  start();
}

/*
 * This function has been moved as inline to <head>
 */

/*
(function legacyRedirect() {

  var isLegacyHash = document.location.hash && document.location.hash.indexOf('#!filter') === 0;

  if (!isLegacyHash) {
    return;
  }

  var url = new URL(document.location.href);
  var lang = document.documentElement.getAttribute('lang') === 'en-US' ? 'en' : 'fi';
  var params = new URLSearchParams('?' + document.location.hash.split('#!').pop());
  var filter = params.get('filter');
  var content_overlay = params.get('content_overlay');
  var redirectMap = {
    contact: 21,
    works: 15,
    news: 19
  };
  var redirect = '/';

  if (content_overlay) {
    redirect = '/' + lang + '/node/' + content_overlay.split('-').pop();
  } else {
    redirect = redirectMap[filter] ? ('/?p=' + redirectMap[filter]) : '/';
  }

  window.location.replace(redirect);

}());
*/
